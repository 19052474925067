
import { defineComponent, ref, reactive } from "vue";
import CloudFun from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";

export default defineComponent({
  components: {
    Grid,
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref({});

    const gridOptions: GridOptions = {
      title: "產品尺寸",
      multiselect: false,
      toolbarConfig: {
        custom: false,
        refresh: true,
      },
      columns: [
        {
          field: "Number",
          title: "尺寸編號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "Name",
          title: "尺寸名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "Ordinal",
          title: "排序值",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
      ],
      promises: {
        query: model
          ? (params) => model.dispatch('size/query', params)
          : undefined,
        queryAll: model ? () => model.dispatch('size/query') : undefined,
        save: model
          ? (params) => model.dispatch('size/save', params)
          : undefined
      },
      modalConfig: { height: "Auto", width: "400" },
    };
    const formOptions = {
      items: [
        {
          field: "Number",
          title: "尺寸編號",
          span: 24,
          itemRender: {
            name: "$input",
            props: {
              placeholder: "請輸入顏色編號"
            },
          },
        },
        {
          field: "Name",
          title: "尺寸名稱",
          span: 24,
          itemRender: {
            name: "$input",
            props: {
              placeholder: "請輸入顏色名稱"
            },
          },
        },
        {
          field: "Ordinal",
          title: "排序",
          span: 24,
          itemRender: {
            name: "$input",
            props: {
              placeholder: "請輸入排序值"
            },
          },
        },
        {
          span: 24,
          align: "right",
          itemRender: {
            name: "$buttons",
            children: [
              { props: { type: "submit", content: "確定", status: "primary" } },
              { props: { type: "reset", content: "重置" } },
            ],
          },
        },
      ],
      rules: {
        Number: [
          {
            type: "string",
            max: 128,
            required: true,
            message: "請輸入編號",
          },
        ],
        Name: [
          {
            type: "string",
            max: 128,
            required: true,
            message: "請輸入名稱",
          },
        ],
      },
    };

    return {
      grid,
      gridOptions,
      formOptions,
    };
  }
});
